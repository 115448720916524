<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors }">
    <div
        class="input-field"
        :class="[{'active': activeInput, 'mb-0': addition, 'block': isBlock, 'disabled': disabled}, classList]"
    >
      <label class="label">{{ label }} <span v-if="requireMark" style="color:red">*</span></label>
      <the-mask
          v-if="mask"
          :mask="mask"
          @focus="focused = true"
          @blur="focused = false"
          :value="value"
          v-model="field"
          :type="fieldType"
          :name="name"
          :readonly="readonly"
          autocomplete="off"
          :disabled="disabled"
      />
      <input
          v-else
          :type="fieldType"
          @focus="focused = true"
          @blur="focused = false"
          v-model="field"
          :name="name"
          :readonly="readonly"
          autocomplete="off"
          :disabled="disabled"
      />
      <SpinLoader v-if="loading"/>
    </div>
<!--    <span class="addition-text text-danger" v-if="errors.length > 0">{{errors ? errors[0]: ''}}</span>-->
    <span class="addition-text" v-if="addition">{{ addition }}</span>
  </ValidationProvider>
</template>

<script>
import {TheMask} from '@/plugins/input-mask'
import SpinLoader from "../SpinLoader";

export default {
  name: "InputField",
  components: {
    SpinLoader,
    TheMask
  },
  props: {
    classList: String,
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    value: null,
    addition: {
      type: String
    },
    fieldType: {
      type: String,
      default: 'text'
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: false
    },
    requireMark: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uppercase: Boolean,
  },
  data() {
    return {
      activeInput: false,
      focused: false,
      field: ''
    }
  },
  computed: {
    divClassList() {

    }
  },
  beforeMount() {
    if (this.value) {
      this.field = this.value;
    }
  },
  methods: {
    setActiveInput() {
      this.activeInput = this.field !== '' || this.focused;
    },
  },
  watch: {
    field(value) {
      this.setActiveInput();
      if(this.uppercase) {
        value = value.toUpperCase();
      }
      this.$emit('input', value);
    },
    value(value) {
      this.field = value;
    },
    focused() {
      this.setActiveInput()
    }
  },
}
</script>

<style lang="scss" scoped>
.input-field {
  position: relative;
  display: flex;
  align-items: center;

  &.active {
    height: 64px;

    .label {
      top: 10px;
      font-size: 14px;
    }

    input {
      position: absolute;
      bottom: 8px;
      height: unset;
    }
  }

  &.disabled {
    input {
      color: #99A3B3 !important;
      -webkit-text-fill-color: #99A3B3;
      opacity: 1; /* required on iOS */
    }
  }

  .label {
    position: absolute;
    color: #99A3B3;
    z-index: 1;
  }

  input {
    height: 100%;
    position: relative;
    z-index: 2;
    background: transparent;
  }
}
</style>
